/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, ThemeProvider } from '@mui/material';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import logError from '../../../../../../../config/static/js/utils/logError';
import TagSelector from '../../../../../../../config/static/js/components/jsx/tags/tagSelector';
import { getCurrentUser, getUserMediaProfile } from '../../utils';

function BasicInfoForm({
  setRegistrationStage = () => {},
  nextRegistrationStage = undefined,
  accountType,
  initialFirstName = '',
  initialLastName = '',
  title = null,
  buttonText = 'Continue',
  continueFromSocial = false,
  setInvitations = () => {},
  guestCheckout = false,
  isFreeTierSubscription = true,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const providerName = urlParams.get('provider');
  const nextParam = urlParams.get('next');
  const fillMediaOutlets = urlParams.get('fill_media_outlets', false);
  // The regular account sign up flow will have guestCheckout as true,
  // while the social sign up flow will have next as the next url
  const isGuestCheckout = guestCheckout || nextParam;

  const [firstName, setFirstName] = useState(initialFirstName);
  const [firstNameError, setFirstNameError] = useState([]);
  const [lastName, setLastName] = useState(initialLastName);
  const [lastNameError, setLastNameError] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState([]);
  const [referralType, setReferralType] = useState('');
  const [referralTypeError, setReferralTypeError] = useState([]);
  const [mediaOutletTags, setMediaOutletTags] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showPhoneField, setShowPhoneField] = useState(true);
  const [providerDisplayName, setProviderDisplayName] = useState('');
  const [providerTag, setProviderTag] = useState('');
  const [brandName, setBrandName] = useState('');
  const [brandNameError, setBrandNameError] = useState([]);
  const [brandWebsite, setBrandWebsite] = useState('');
  const [brandWebsiteError, setBrandWebsiteError] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [industriesError, setIndustriesError] = useState([]);
  const [onboardWithAi, setOnboardWithAi] = useState(true);

  const errorHandlingMap = {
    first_name: setFirstNameError,
    last_name: setLastNameError,
    phone_number: setPhoneNumberError,
    referral_type: setReferralTypeError,
    title: setBrandNameError,
    website: setBrandWebsiteError,
    industries: setIndustriesError,
  };

  React.useEffect(() => {
    if (providerName === 'linkedin_oauth2') {
      setProviderDisplayName('LinkedIn');
      setProviderTag('linkedin');
    } else if (providerName && providerName.length > 1) {
      setProviderDisplayName(
        providerName.charAt(0).toUpperCase() + providerName.slice(1)
      );
      setProviderTag(providerName);
    }
  }, [providerName]);

  React.useEffect(() => {
    if (accountType === 'media') {
      setShowPhoneField(false);
      setPhoneNumber('');
    } else {
      setShowPhoneField(true);
    }
  }, [accountType]);

  React.useEffect(() => {
    (async () => {
      if (continueFromSocial || accountType === 'media') {
        const user = await getCurrentUser();

        if (user) {
          setFirstName(user.first_name);
          setLastName(user.last_name);
        }

        if (user.profile_pk) {
          const mediaProfile = await getUserMediaProfile(user.profile_pk);

          if (mediaProfile) {
            const [profileFirstName, profileLastName] = mediaProfile.name.split(
              ' ',
              2
            );
            setFirstName(profileFirstName);
            setLastName(profileLastName);
            setMediaOutletTags(mediaProfile.media_outlets);
          }
        }
      }
    })();
  }, [continueFromSocial, accountType]);

  const handleBasicInfoSubmit = async (e) => {
    setProcessing(true);
    e.preventDefault();
    try {
      const res = await fetch(`/api/users/current-user/finish-signup/`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          referral_type: referralType,
          media_outlets: mediaOutletTags,
          brand_name: brandName,
          brand_website: brandWebsite,
          industries: industries,
          onboard_with_ai: onboardWithAi,
          is_free_tier_subscription: isFreeTierSubscription,
          guest_checkout: isGuestCheckout, // This must be sent to the backend so it doesn't log the user out
        }),
      });
      if (res.ok) {
        res.json().then((data) => {
          if (data.invitations?.length > 0) {
            setRegistrationStage(4);
            setInvitations(data.invitations);
          } else {
            setRegistrationStage(nextRegistrationStage);
          }
        });
      } else {
        res.json().then((errDict) => {
          setProcessing(false);
          const fields = Object.keys(errDict);
          fields.forEach((field) => {
            const formattedError = errDict[field][0].replace(/'/g, '"');
            let error;
            try {
              error = JSON.parse(formattedError);
            } catch {
              error = formattedError;
            }
            if (!Array.isArray(error)) {
              errorHandlingMap[field]([error]);
            } else {
              errorHandlingMap[field](error);
            }
          });
        });
      }
    } catch (err) {
      logError(err);
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleBasicInfoSubmit}>
      {title && <h3 className="multi-step-title">{title}</h3>}
      {continueFromSocial && (
        <div className="registration-info-row social-signin__provider-name-and-icon">
          {providerName && (
            <>
              <img
                src={`${staticUrl}/images/svg/logos_${providerTag}-icon.svg`}
                alt={providerDisplayName}
              />
              <p
                className={`social-signin__provider-text social-signin__provider-text--${providerTag}`}
              >
                Signed in with {providerDisplayName}
              </p>
            </>
          )}
        </div>
      )}
      <div className="registration-info-row registration-info-row--split">
        <div className="registration-info-row__column basic-info-row__column--split">
          <label className="registration-info-input-element__title">
            First Name
          </label>
          <input
            className="registration-info-input-element"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            maxLength={30}
            required
          />
          {firstNameError.map((errorString, key) => (
            <div
              className="registration-info-input-element__error-text"
              key={key}
            >
              {errorString}
            </div>
          ))}
        </div>
        <div className="registration-info-row__column basic-info-row__column--split">
          <label className="registration-info-input-element__title">
            Last Name
          </label>
          <input
            className="registration-info-input-element"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            maxLength={30}
            required
          />
          {lastNameError.map((errorString, key) => (
            <div
              className="registration-info-input-element__error-text"
              key={key}
            >
              {errorString}
            </div>
          ))}
        </div>
      </div>
      <div className="registration-info-row registration-info-row--split">
        {showPhoneField && (
          <div className="registration-info-row__column registration-info-row__column--split">
            <label className="registration-info-input-element__title">
              Phone Number
            </label>
            <input
              className="registration-info-input-element"
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {phoneNumberError.map((errorString, key) => (
              <div
                className="registration-info-input-element__error-text"
                key={key}
              >
                {errorString}
              </div>
            ))}
          </div>
        )}
        <div className="registration-info-row__column registration-info-row__column--split">
          <label className="registration-info-input-element__title">
            How did you hear about us?
          </label>
          <select
            defaultValue={referralType}
            className="registration-info-input-element"
            onChange={(e) => setReferralType(e.target.value)}
          >
            <option value="">-</option>
            <option value="SEA">Search engine</option>
            <option value="REC">Recommended by friend or colleague</option>
            <option value="SOC">Social media</option>
            <option value="EMA">Email</option>
            <option value="PUB">Blog or Publication</option>
            <option value="OTH">Other</option>
          </select>
          {referralTypeError.map((errorString, key) => (
            <div
              className="registration-info-input-element__error-text"
              key={key}
            >
              {errorString}
            </div>
          ))}
        </div>
      </div>
      {accountType === 'media' && (
        <>
          <div className="dotted-bottom-new" />
          <div className="registration-info-row registration-info-row--mt">
            <div className="registration-info-row__column">
              {fillMediaOutlets && (
                <ThemeProvider theme={PressHookTheme}>
                  <Alert severity="error" sx={{ width: '100%' }}>
                    Please, select at least one outlet you write for
                  </Alert>
                </ThemeProvider>
              )}
              <label className="registration-info-input-element__title">
                Outlets I Frequently Write For
              </label>
              <TagSelector
                tags={mediaOutletTags}
                tagType="media-outlet-tags"
                onChange={setMediaOutletTags}
                hybrid
                placeholder="Enter or search outlets..."
                required
                tagName="media outlet"
                typeSuggestion="The New York Times, CNN, HuffPost"
              />
            </div>
          </div>
        </>
      )}
      {accountType === 'brand' && (
        <>
          <div className="dotted-bottom-new" />
          <div className="registration-info-row registration-info-row--mt">
            <div className="registration-info-row__column">
              <label className="registration-info-input-element__title">
                Brand Name
              </label>
              <input
                className="registration-info-input-element"
                type="text"
                placeholder="Brand Name"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                maxLength={30}
                required
              />
              {brandNameError.map((errorString, key) => (
                <div
                  className="registration-info-input-element__error-text"
                  key={key}
                >
                  {errorString}
                </div>
              ))}
            </div>
          </div>
          <div className="registration-info-row registration-info-row--mt">
            <div className="registration-info-row__column">
              <label className="registration-info-input-element__title">
                Brand Website
              </label>
              <input
                className="registration-info-input-element"
                type="url"
                placeholder="Brand Website"
                value={brandWebsite}
                onChange={(e) => setBrandWebsite(e.target.value)}
                required
              />
              {brandWebsiteError.map((errorString, key) => (
                <div
                  className="registration-info-input-element__error-text"
                  key={key}
                >
                  {errorString}
                </div>
              ))}
            </div>
          </div>
          <div className="registration-info-row registration-info-row--mt">
            <div className="registration-info-row__column">
              <label className="registration-info-input-element__title">
                Industries
              </label>
              <TagSelector
                tagType="industries"
                tags={industries}
                onChange={setIndustries}
                placeHolder={'Select industries...'}
              />
              {industriesError.map((errorString, key) => (
                <div
                  className="registration-info-input-element__error-text"
                  key={key}
                >
                  {errorString}
                </div>
              ))}
            </div>
          </div>
          <div className="registration-info-row registration-info-row--mt">
            <div className="registration-info-row__column">
              <label className="registration-info-input-element__title">
                <input
                  type="checkbox"
                  checked={onboardWithAi}
                  onChange={(e) => setOnboardWithAi(e.target.checked)}
                />{' '}
                Use AI to help generate my Brand Profile
              </label>
            </div>
          </div>
        </>
      )}
      <button className="multi-step-button" type="submit" disabled={processing}>
        {processing ? 'Submitting...' : buttonText}
      </button>
    </form>
  );
}

BasicInfoForm.propTypes = {
  setRegistrationStage: PropTypes.func,
  nextRegistrationStage: PropTypes.number,
  initialFirstName: PropTypes.string,
  initialLastName: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  accountType: PropTypes.string,
  continueFromSocial: PropTypes.bool,
  setInvitations: PropTypes.func,
  guestCheckout: PropTypes.bool,
  isFreeTierSubscription: PropTypes.bool,
};

export default BasicInfoForm;
