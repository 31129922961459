import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { getSetupIntentClientSecret } from '../../utils';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function PaymentInfoForm({
  userIsExistingCustomer,
  selectedPrice,
  selectedProductName,
  selectedPriceTerm,
  preSelectedPriceData,
  setRegistrationStage,
  nextRegistrationStage,
  initialPromoCode = undefined,
  promoCode,
  setPromoCode,
  trialLength,
  setTrialLength,
  couponData,
  setCouponData,
  rawPriceTotal = undefined,
  setRawPriceTotal,
  rawTotalDue,
  rawTotalWithDiscount,
  userIsAuthenticated,
  setSubscriptionId,
  organizationPk = undefined,
}) {
  const apiKey = document.querySelector('[data-stripe-api-key]').dataset
    .stripeApiKey;
  const stripePromise = loadStripe(apiKey);

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (userIsAuthenticated) {
      getSetupIntentClientSecret().then((res) => {
        setClientSecret(res);
      });
    }
  }, [userIsAuthenticated]);

  return (
    <div>
      <div className="multi-step-flex-header multi-step-flex-header--with-controls">
        <h3 className="multi-step-flex-header__title">Payment Details:</h3>
        <div className="multi-step-flex-header__controls">
          <div className="multi-step-flex-header__plan-selection">
            <span className="multi-step-flex-header__plan-name">
              (
              {preSelectedPriceData.product_name
                ? preSelectedPriceData.product_name
                : selectedProductName}
              )
            </span>{' '}
            {preSelectedPriceData.term
              ? preSelectedPriceData.term
              : selectedPriceTerm}
          </div>
          {trialLength > 0 && <span>{trialLength} day trial included</span>}
          <button
            type="button"
            className="multi-step-flex-header__back-button"
            onClick={() => setRegistrationStage(2)}
          >
            Change plan
          </button>
        </div>
      </div>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <StripeCheckoutForm
            userIsExistingCustomer={userIsExistingCustomer}
            selectedPrice={
              preSelectedPriceData.id
                ? preSelectedPriceData.tier_selected
                : selectedPrice
            }
            trialLength={trialLength}
            setTrialLength={setTrialLength}
            setRegistrationStage={setRegistrationStage}
            nextRegistrationStage={nextRegistrationStage}
            initialPromoCode={initialPromoCode}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            rawTotalDue={rawTotalDue}
            rawTotalWithDiscount={rawTotalWithDiscount}
            rawPriceTotal={rawPriceTotal}
            setRawPriceTotal={setRawPriceTotal}
            couponData={couponData}
            setCouponData={setCouponData}
            clientSecret={clientSecret}
            setSubscriptionId={setSubscriptionId}
            organizationPk={organizationPk}
          />
        </Elements>
      ) : (
        <>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={20} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={90} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={20} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={60} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={60} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={40} />
          </div>
        </>
      )}
    </div>
  );
}

PaymentInfoForm.propTypes = {
  userIsExistingCustomer: PropTypes.bool.isRequired,
  selectedPrice: PropTypes.string.isRequired,
  selectedProductName: PropTypes.string.isRequired,
  selectedPriceTerm: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preSelectedPriceData: PropTypes.object.isRequired,
  setRegistrationStage: PropTypes.func.isRequired,
  nextRegistrationStage: PropTypes.number.isRequired,
  initialPromoCode: PropTypes.string,
  promoCode: PropTypes.string.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  trialLength: PropTypes.number.isRequired,
  setTrialLength: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  couponData: PropTypes.object.isRequired,
  setCouponData: PropTypes.func.isRequired,
  rawPriceTotal: PropTypes.number,
  setRawPriceTotal: PropTypes.func.isRequired,
  rawTotalDue: PropTypes.number.isRequired,
  rawTotalWithDiscount: PropTypes.number.isRequired,
  userIsAuthenticated: PropTypes.bool.isRequired,
  setSubscriptionId: PropTypes.func.isRequired,
  organizationPk: PropTypes.number,
};

export default PaymentInfoForm;
