import getCookie from '../../../../../config/static/js/utils/getCookie';
import logError from '../../../../../config/static/js/utils/logError';

// Any user data fetched from the server will be stored here to prevent multiple requests
export const userData = {};

// Array to keep track of which requests are currently being fetched
const isFetching = [];

/**
 * Get the user's authentication status
 * @returns auth-status object
 */
export async function getUserAuthStatus() {
  if (isFetching.includes('authStatus')) {
    while (isFetching.includes('authStatus')) {
      // eslint-disable-next-line no-await-in-loop -- this is intentional
      await new Promise((resolve) => {setTimeout(resolve, 100)});
    }
    return userData.authStatus;
  }

  if (!userData.authStatus) {
    isFetching.push('authStatus');
    try {
      const response = await fetch('/api/users/auth-status/', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      });
      const json = await response.json();
      userData.authStatus = json;
    } finally {
      isFetching.splice(isFetching.indexOf('authStatus'), 1);
    }
  }

  return userData.authStatus;
}

export async function getCurrentUser() {
  const response = await fetch('/api/users/current-user/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
  });
  const json = await response.json();
  return json;
}

export async function getCurrentUserExtraData() {
  const response = await fetch('/api/users/current-user/prefill-signup/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
  });
  const json = await response.json();
  return json;
}

export async function getUserMediaProfile(pk) {
  if (pk === null) return null;
  try {
    const response = await fetch(`/api/media-profiles/${pk}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
        'Cache-Control': 'no-cache',
      },
    });
    if (response.status !== 200) return null;
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function getUserBrand(pk) {
  if (pk === null) return null;
  try {
    const response = await fetch(`/api/brands/${pk}/?latest_revision=True`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
        'Cache-Control': 'no-cache',
      },
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function createSubscriptionWithPaymentMethod(
  priceId,
  trialLength,
  quantity,
  paymentMethodId,
  businessName,
  promoCodeId,
  organizationPk,
  couponId,
  objectId,
  objectType,
  newObjectData
) {
  try {
    const response = await fetch('/api/stripe/subscriptions/create/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        price_id: priceId,
        trial_length: trialLength,
        quantity,
        payment_method_id: paymentMethodId,
        business_name: businessName,
        promo_code_id: promoCodeId,
        organization_pk: organizationPk,
        coupon_id: couponId,
        object_id: objectId,
        object_type: objectType,
        new_object_data: newObjectData,
      }),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return {
      error:
        'There was an error processing your subscription. Please try again.',
    };
  }
}

export async function getSetupIntentClientSecret() {
  try {
    const response = await fetch('/api/stripe/setupintent/create/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    });
    const json = await response.json();
    return json.client_secret;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function getPriceDetails(priceId) {
  try {
    const response = await fetch(`/api/stripe/prices/${priceId}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

/**
 * Fetches the details of a pricing plan
 * @param {string} priceId 
 * @param {boolean} isPlanSelection 
 * @returns 
 */
export async function getPricingPlanDetails(priceId, isPlanSelection = false) {
  try {
    const response = await fetch(
      `/api/pricing-plans/${priceId}/${
        isPlanSelection ? '?is_plan_selection=True' : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      }
    );
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function createSubscriptionWithoutPaymentMethod(
  priceId,
  organizationPk
) {
  try {
    const response = await fetch('/api/stripe/subscriptions/create/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        price_id: priceId,
        organization_pk: organizationPk,
      }),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return {
      error:
        'There was an error processing your subscription. Please try again.',
    };
  }
}

export function getTotalUnitAmountFromQuantity(
  product,
  quantity = null,
  annual = false
) {
  let price;
  let totalUnitAmount = 0;
  let currentQuantity = quantity;

  if (!currentQuantity) {
    currentQuantity = product.minimum_quantity;
  }
  if (annual) {
    price = product.yearly_price;
  } else {
    price = product.monthly_price;
  }

  if (product.is_tiered) {
    const { tiers } = price;
    tiers.some((tier) => {
      if (currentQuantity <= 0) return true;

      if (tier.flat_amount !== null) {
        // If it's a flat amount tier, add the flat amount to the total price
        totalUnitAmount += tier.flat_amount;
        currentQuantity -= tier.up_to;
      } else {
        // If it's a unit amount tier, calculate the price for the quantities in this tier
        const tierQuantity = tier.up_to
          ? Math.min(quantity, tier.up_to - tiers[0].up_to)
          : currentQuantity;
        totalUnitAmount += tierQuantity * tier.unit_amount;
        currentQuantity -= tierQuantity;
      }
      return false;
    });
  } else {
    totalUnitAmount = price.unit_amount * currentQuantity;
  }

  return totalUnitAmount;
}

/**
 * Gets the active promo for a product
 * @param {object} product A product object from the pricing-plans API
 * @param {boolean} isAnnual the subscription term
 * @returns 
 */
export function getActivePromoForProduct(product, isAnnual) {
  const urlBasedPromos = product.promos.filter((promo) => 
    promo.url_path_requirement &&
    window.location.pathname.includes(promo.url_path_requirement) &&
    promo.subscription_term === (isAnnual ? 'annual' : 'monthly')
  );

  // If there is a promo that matches the current URL, return it
  if (urlBasedPromos.length > 0) return urlBasedPromos[0];

  const defaultPromos = product.promos.filter((promo) =>
    promo.is_default &&
    promo.subscription_term === (isAnnual ? 'annual' : 'monthly')
  );

  // If there is a default promo for the product, return it
  if (defaultPromos.length > 0) return defaultPromos[0];

  // No active promo found
  return null;
}