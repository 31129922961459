import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccountTypeSelectForm from './AccountTypeSelectForm';
import BasicInfoForm from './BasicInfoForm';
import PlanSelectionForm from './PlanSelectionForm';
import PaymentInfoForm from './PaymentInfoForm';
import { getUserAuthStatus } from '../../utils';
import FinishSignUp from './FinishSignUp';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function MultiStepRegistrationForm({
  promotedPriceId = null,
  promotedTrialLength = null,
  defaultAccountType = null,
  linkedinSignInURL,
  googleSignInURL,
  microsoftSignInURL,
  shopifySignInURL,
  csrfToken,
  hiddenAccountSelector = false,
  guestCheckout = false,
  mediaProfilePk = null,
  obscuredEmail = null,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const isAmex = window.location.pathname.includes('amex');

  // Initial Basic Info
  const initialType = urlParams.get('type')
    ? urlParams.get('type')
    : defaultAccountType;
  const initialFirstName = urlParams.get('firstName', '');
  const initialLastName = urlParams.get('lastName', '');
  const [initialEmail, _setInitialEmail] = React.useState(
    urlParams.get('email', '')
  );
  const initialPromoCode = urlParams.get('promo', '');
  const invitationId = urlParams.get('invitation', '');

  // Social Login Error
  const socialLoginErrorDescription = urlParams.get('error', '');

  // Should it continue registration from a social signup?
  const continueFromSocial = urlParams.get('continue_from_social', false);

  // Social Login Provider
  const socialLoginProvider = urlParams.get('provider', '');

  // Premium Price Selection Info
  const preSelectedPriceData = document.querySelector(
    '[data-preselected-price-data]'
  )?.dataset.preselectedPriceData;

  const expertsOn =
    document.querySelector('[data-experts-on]')?.dataset.expertsOn === 'yes';

  let selectedPriceHistory;
  let selectedProductNameHistory;
  let selectedTermHistory;

  if (preSelectedPriceData) {
    const data = JSON.parse(preSelectedPriceData);
    selectedPriceHistory = data.id;
    selectedProductNameHistory = data.product_name;
    selectedTermHistory = data.term;
  } else {
    selectedPriceHistory = sessionStorage.getItem('selectedPriceHistory');
    selectedProductNameHistory = sessionStorage.getItem(
      'selectedProductNameHistory'
    );
    selectedTermHistory = sessionStorage.getItem('selectedTermHistory');
  }

  const [products, setProducts] = useState([]);
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
  const [userIsExistingCustomer, setUserIsExistingCustomer] = useState(false);
  const [organizationPk, setOrganizationPk] = useState(null);
  const [registrationStage, setRegistrationStage] = useState(
    selectedPriceHistory ? 2 : null
  );
  const [selectedPrice, setSelectedPrice] = useState(
    selectedPriceHistory || ''
  );
  const [selectedProductName, setSelectedProductName] = useState(
    selectedProductNameHistory || ''
  );
  const [selectedPriceTerm, setSelectedPriceTerm] = useState(
    selectedTermHistory || ''
  );
  const [rawPriceTotal, setRawPriceTotal] = useState(null);
  const [promoCode, setPromoCode] = useState(initialPromoCode || '');
  const [couponData, setCouponData] = useState({});
  const [trialLength, setTrialLength] = useState(0);
  const [accountType, setAccountType] = useState(initialType);
  const [rawTotalDue, setRawTotalDue] = useState(0);
  const [rawTotalWithDiscount, setRawTotalWithDiscount] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [invitations, setInvitations] = useState([]);

  // Decide what the next step should be for Basic Info
  let basicInfoNextStep;

  if (
    accountType === 'brand' ||
    accountType === 'agency' ||
    accountType === 'expert'
  ) {
    if (preSelectedPriceData) {
      basicInfoNextStep = 3;
    } else if (accountType === 'brand') {
      // If there's no pre-selected price, skip plan and payment stages
      basicInfoNextStep = 4;
    } else {
      basicInfoNextStep = 2;
    }
  } else {
    // media
    basicInfoNextStep = 4;
  }

  // Decide what the title should be for Basic Info
  const [basicInfoTitle, setBasicInfoTitle] = useState('Create your account');

  useEffect(() => {
    if (accountType === 'media') {
      if (initialEmail) {
        setBasicInfoTitle('Claim your profile');
      } else if (registrationStage === 0) {
        setBasicInfoTitle('Create your account');
      } else {
        setBasicInfoTitle('Finish your account setup');
      }
    }
  }, [accountType, initialType, initialEmail]);

  useEffect(() => {
    sessionStorage.setItem('selectedPriceHistory', selectedPrice);
    sessionStorage.setItem('selectedProductNameHistory', selectedProductName);
    sessionStorage.setItem('selectedTermHistory', selectedPriceTerm);
  }, [selectedPrice, selectedProductName, selectedPriceTerm]);

  useEffect(() => {
    // If there is a coupon and no trial length, do some math
    if (couponData) {
      let rawDiscount = 0;
      if (couponData.amount_off) {
        rawDiscount += couponData.amount_off;
      }
      if (couponData.percent_off) {
        rawDiscount += rawPriceTotal * (couponData.percent_off / 100);
      }
      setRawTotalDue(rawPriceTotal - rawDiscount);
      setRawTotalWithDiscount(rawPriceTotal - rawDiscount);
    } else {
      setRawTotalDue(rawPriceTotal);
    }

    if (trialLength) {
      setRawTotalDue(0);
    }
  }, [rawPriceTotal, couponData]);

  useEffect(() => {
    getUserAuthStatus().then((data) => {
      if (data.status === true) {
        setUserIsAuthenticated(true);
        setAccountType(data.account_type);
        setUserIsExistingCustomer(data.is_existing_customer);
        setOrganizationPk(data.organization_pk);

        if (continueFromSocial) {
          // continuing from social sign up
          if (socialLoginProvider === 'shopify') {
            // for now, shopify connection should skip everything and go directly to the dashboard
            // it's coming from the shopify store app installation button
            window.location.href = '/';
          } else {
            setRegistrationStage(1);
          }
        } else if (selectedPrice) {
          setRegistrationStage(3);
        } else if (
          data.account_type === 'media' ||
          data.account_type === 'brand'
        ) {
          setRegistrationStage(1);
        } else {
          setRegistrationStage(2);
        }
      } else {
        setRegistrationStage(0);
      }
    });

    if (preSelectedPriceData) {
      const data = JSON.parse(preSelectedPriceData);
      setSelectedPrice(data.id);
      setSelectedProductName(data.product_name);
      setSelectedPriceTerm(data.term);
      setTrialLength(data.trial_length);
    }
  }, []);

  useEffect(() => {
    if (!organizationPk) {
      getUserAuthStatus().then((data) => {
        if (data.status === true) {
          setUserIsAuthenticated(true);
          setAccountType(data.account_type);
          setUserIsExistingCustomer(data.is_existing_customer);
          setOrganizationPk(data.organization_pk);
        }
      });
    }
  }, [registrationStage]);

  const stageMapping = {
    0: {
      form: (
        <AccountTypeSelectForm
          setRegistrationStage={setRegistrationStage}
          nextRegistrationStage={1}
          title={!hiddenAccountSelector ? basicInfoTitle : null}
          subtitle={
            !hiddenAccountSelector
              ? 'Fill the information below to get started!'
              : null
          }
          accountType={accountType}
          setAccountType={setAccountType}
          initialEmail={initialEmail}
          setUserIsAuthenticated={setUserIsAuthenticated}
          expertsOn={expertsOn}
          linkedinSignInURL={linkedinSignInURL}
          googleSignInURL={googleSignInURL}
          microsoftSignInURL={microsoftSignInURL}
          shopifySignInURL={shopifySignInURL}
          csrfToken={csrfToken}
          isAmex={isAmex}
          invitationId={invitationId}
          selectableAccountType={!hiddenAccountSelector && accountType !== ''}
          guestCheckout={guestCheckout}
          mediaProfilePk={mediaProfilePk}
          obscuredEmail={obscuredEmail}
        />
      ),
    },
    1: {
      form: (
        <BasicInfoForm
          setRegistrationStage={setRegistrationStage}
          nextRegistrationStage={basicInfoNextStep}
          accountType={accountType}
          title={basicInfoTitle}
          initialFirstName={initialFirstName}
          initialLastName={initialLastName}
          continueFromSocial={continueFromSocial}
          setInvitations={setInvitations}
          guestCheckout={guestCheckout}
          isFreeTierSubscription={selectedPrice === ''}
        />
      ),
    },
    2: {
      form: (
        <PlanSelectionForm
          userIsExistingCustomer={userIsExistingCustomer}
          setRegistrationStage={setRegistrationStage}
          skipPaymentMethodRegistrationStage={4}
          paymentMethodRegistrationStage={3}
          setSelectedPrice={setSelectedPrice}
          selectedPrice={selectedPrice}
          promotedPriceId={promotedPriceId}
          setSelectedProductName={setSelectedProductName}
          selectedProductName={selectedProductName}
          setSelectedPriceTerm={setSelectedPriceTerm}
          selectedPriceTerm={selectedPriceTerm}
          title="Select a Plan:"
          setTrialLength={setTrialLength}
          accountType={accountType}
          setRawPriceTotal={setRawPriceTotal}
          setSubscriptionId={setSubscriptionId}
          initialPromoCode={initialPromoCode}
          setPromoCode={setPromoCode}
          products={products}
          setProducts={setProducts}
          setCouponData={setCouponData}
          isAmex={isAmex}
          organizationPk={organizationPk}
        />
      ),
    },
    3: {
      form: (
        <PaymentInfoForm
          userIsExistingCustomer={userIsExistingCustomer}
          selectedPrice={selectedPrice}
          selectedPriceTerm={selectedPriceTerm}
          selectedProductName={selectedProductName}
          trialLength={trialLength}
          setTrialLength={setTrialLength}
          preSelectedPriceData={{}}
          promotedTrialLength={promotedTrialLength}
          promotedPriceId={promotedPriceId}
          title="Payment Details"
          setRegistrationStage={setRegistrationStage}
          nextRegistrationStage={4}
          initialPromoCode={initialPromoCode}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          couponData={couponData}
          setCouponData={setCouponData}
          rawPriceTotal={rawPriceTotal}
          setRawPriceTotal={setRawPriceTotal}
          rawTotalDue={rawTotalDue}
          rawTotalWithDiscount={rawTotalWithDiscount}
          userIsAuthenticated={userIsAuthenticated}
          setSubscriptionId={setSubscriptionId}
          organizationPk={organizationPk}
        />
      ),
    },
    4: {
      form: (
        <FinishSignUp
          accountType={accountType}
          subscriptionId={subscriptionId}
          invitations={invitations}
          guestCheckout={guestCheckout}
        />
      ),
    },
  };

  return (
    <div className="react-registration-form">
      {socialLoginErrorDescription && (
        <p className="social-signin__error">{socialLoginErrorDescription}</p>
      )}
      {registrationStage === null ? (
        <>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={80} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={40} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={40} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={40} />
          </div>
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={40} />
          </div>
        </>
      ) : (
        stageMapping[registrationStage].form
      )}
    </div>
  );
}

MultiStepRegistrationForm.propTypes = {
  promotedPriceId: PropTypes.string,
  promotedTrialLength: PropTypes.number,
  defaultAccountType: PropTypes.string,
  linkedinSignInURL: PropTypes.string.isRequired,
  googleSignInURL: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  hiddenAccountSelector: PropTypes.bool,
  guestCheckout: PropTypes.bool,
  mediaProfilePk: PropTypes.number,
  obscuredEmail: PropTypes.string,
};

export default MultiStepRegistrationForm;
