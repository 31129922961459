/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createSubscriptionWithoutPaymentMethod,
  getPriceDetails,
  getTotalUnitAmountFromQuantity,
  getActivePromoForProduct,
} from '../../utils';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function PlanSelectionForm({
  userIsExistingCustomer,
  setRegistrationStage,
  skipPaymentMethodRegistrationStage,
  paymentMethodRegistrationStage,
  setSelectedPrice,
  selectedPrice,
  setSelectedProductName,
  selectedProductName,
  setSelectedPriceTerm,
  selectedPriceTerm,
  setTrialLength,
  accountType = undefined,
  setRawPriceTotal,
  setSubscriptionId,
  initialPromoCode = undefined,
  setPromoCode,
  products,
  setProducts,
  setCouponData,
  isAmex,
  organizationPk = undefined,
}) {
  const [showAnnual, setShowAnnual] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedPriceUnitAmount, setSelectedPriceUnitAmount] = useState(null);

  /**
   * Create a subscription without a payment method (for free tier plans)
   */
  function freeTierAction() {
    createSubscriptionWithoutPaymentMethod(selectedPrice, organizationPk).then(
      (r) => {
        if (!Object.prototype.hasOwnProperty.call(r, 'error')) {
          setSubscriptionId(r.id);
          setRegistrationStage(skipPaymentMethodRegistrationStage);
        } else {
          setErrorMessage(r.error);
        }
      }
    );
  }

  useEffect(() => {
    // If a price is selected, check if it is a free tier
    if (selectedPrice) {
      getPriceDetails(selectedPrice).then((price) => {
        if (price.unit_amount === 0) freeTierAction();
      });
    }

    fetch(`/api/pricing-plans/?type=${accountType}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setProcessing(false);

        if (data.length === 1 && !selectedPrice) {
          setSelectedPrice(
            data[0][showAnnual ? 'yearly_price' : 'monthly_price']?.id
          );
          setSelectedPriceUnitAmount(
            data[0][showAnnual ? 'yearly_price' : 'monthly_price']?.unit_amount
          );
        }
      });
  }, []);

  /**
   * Moves to the next step in the registration process
   */
  function nextStep() {
    setProcessing(true);
    if (selectedPriceUnitAmount === 0) {
      freeTierAction();
    } else {
      setRegistrationStage(paymentMethodRegistrationStage);
    }
    setProcessing(true);
  }

  function setSelection(product) {
    if (!selectedPrice) {
      window.analytics?.track('Plan Selected', {
        price_id: product[showAnnual ? 'yearly_price' : 'monthly_price']?.id,
        plan_name: product.name,
        plan_term: showAnnual ? 'Annual' : 'Monthly',
      });
    } else {
      window.analytics?.track('Plan Selection Changed', {
        new_price_id:
          product[showAnnual ? 'yearly_price' : 'monthly_price']?.id,
        new_plan_name: product.name,
        new_plan_term: showAnnual ? 'Annual' : 'Monthly',
        old_price_id: selectedPrice,
        old_plan_name: selectedProductName,
        old_plan_term: selectedPriceTerm,
      });
    }

    setSelectedPrice(
      product[showAnnual ? 'yearly_price' : 'monthly_price']?.id
    );
    setSelectedPriceUnitAmount(
      product[showAnnual ? 'yearly_price' : 'monthly_price']?.unit_amount
    );
    setSelectedProductName(product.name);
    setSelectedPriceTerm(showAnnual ? 'Annual' : 'Monthly');

    // initialPromoCode overrides the product default/url promo, so no need to get it
    if (!initialPromoCode) {
      const promo = getActivePromoForProduct(product, showAnnual);
      if (promo) {
        if (promo.trial_days) {
          setTrialLength(promo.trial_days);
        }
        if (promo.promo_code) {
          setPromoCode(promo.promo_code);
        }
      } else {
        setTrialLength(0);
        setPromoCode('');
        setCouponData({});
      }
    }

    setRawPriceTotal(getTotalUnitAmountFromQuantity(product, null, showAnnual));
  }

  function getDisplayPrice(product, showAnnualValue) {
    if (showAnnualValue) {
      // remove decimal places if they are 0
      return product.yearly_price_usd_unit_amount % 1 === 0
        ? parseInt(product.yearly_price_usd_unit_amount, 10)
        : product.yearly_price_usd_unit_amount;
    }
    return product.monthly_price_usd_unit_amount % 1 === 0
      ? parseInt(product.monthly_price_usd_unit_amount, 10)
      : product.monthly_price_usd_unit_amount;
  }

  function getPromoComponent(product, isAnnual) {
    // initialPromoCode overrides the product default/url, so no need to get it
    // also, if already a paying customer, we won't give default/url promos to them.
    if (initialPromoCode || userIsExistingCustomer) {
      return '';
    }

    const promo = getActivePromoForProduct(product, isAnnual);
    if (promo) {
      return (
        <div className={`promo-block ${promo.css_class}`}>
          <img src={promo.icon} className="promo-block__icon" alt='' />
          <div className="promo-block__text">{promo.text}</div>
          <div className={`promo-block__label ${promo.label_css_class}`}>
            {promo.label}
          </div>
        </div>
      );
    }
    return '';
  }

  return (
    <div>
      <div className="plan-selection-component">
        <div className="multi-step-flex-header">
          <h3 className="multi-step-flex-header__title">Select a Plan:</h3>
          <div className="multi-step-flex-header__term-switch">
            <div
              className={`multi-step-flex-header__term-name ${
                !showAnnual ? 'multi-step-flex-header__term-name--active' : ''
              }`}
            >
              Billed Monthly
            </div>
            <label className="toggle-switch">
              <input
                id="pricingToggleSwitch"
                className="toggle-switch__input"
                type="checkbox"
                value={showAnnual}
                onChange={() => setShowAnnual(!showAnnual)}
              />
              {/* jsx-a11y/label-has-associated-control requires text context */}
              <span className="toggle-switch__slider toggle-switch__slider--round toggle-switch__slider--stay-red"/> 
            </label>
            <div
              className={`multi-step-flex-header__term-name ${
                showAnnual ? 'multi-step-flex-header__term-name--active' : ''
              }`}
            >
              Billed Annually
              {products[0]?.discount_display_string && !isAmex && (
                <div
                  className={`multi-step-flex-header__term-promo ${
                    showAnnual
                      ? 'multi-step-flex-header__term-promo--active'
                      : ''
                  }`}
                >
                  ({products[0]?.discount_display_string})
                </div>
              )}
            </div>
          </div>
        </div>
        {processing ? (
          <>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={145} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={145} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={145} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={20} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={40} />
            </div>
          </>
        ) : (
          <>
            {errorMessage && <div>{errorMessage}</div>}
            <div className="plans-container">
              {products.map((product) => {
              const priceString = showAnnual ? 'yearly_price' : 'monthly_price';
              const isSelected = selectedPrice === product[priceString]?.id;
              return product[priceString] ? (
                  <div
                    className={`plan-item ${
                      isSelected && 'plan-item--selected'
                    }`}
                    onClick={() => setSelection(product)}
                    key={product.id}
                    data-testid="planSelectionItem"
                  >
                    {getPromoComponent(product, showAnnual)}
                    <div className="plan-item__header">
                      <div className="plan-item__header__title">
                        {product.name}
                      </div>
                      <div className="plan-item__header__price">
                        ${getDisplayPrice(product, showAnnual)}/mo
                      </div>
                      {product.is_tiered && (
                        <div
                          className="plan-item__header__price-detail"
                          data-testid="perClientDesc"
                        >
                          per brand
                        </div>
                      )}
                    </div>
                    <div className="plan-item__content">
                      {product.description}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              )}
            </div>
            {products[0]?.disclaimer_display_string && (
              <p className="pricing-disclaimer">
                {products[0]?.disclaimer_display_string}
              </p>
            )}
            <p
              className="pricing-details"
              onClick={() =>
                window.open(
                  '/pricing',
                  'winname',
                  `directories=no,titlebar=no,toolbar=no,location=no,status=no,`
                  `menubar=no,scrollbars=no,resizable=no,width=800,height=600`
                )
              }
            >
              ℹ Pricing Details
            </p>
            <button
              type="button"
              className="multi-step-button"
              onClick={nextStep}
              disabled={!selectedPrice}
              data-testid="continueButton"
            >
              {selectedPrice ? 'Continue' : 'Please select a plan'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

PlanSelectionForm.propTypes = {
  userIsExistingCustomer: PropTypes.bool.isRequired,
  setRegistrationStage: PropTypes.func.isRequired,
  skipPaymentMethodRegistrationStage: PropTypes.number.isRequired,
  paymentMethodRegistrationStage: PropTypes.number.isRequired,
  setSelectedPrice: PropTypes.func.isRequired,
  selectedPrice: PropTypes.string.isRequired,
  setSelectedProductName: PropTypes.func.isRequired,
  selectedProductName: PropTypes.string.isRequired,
  setSelectedPriceTerm: PropTypes.func.isRequired,
  selectedPriceTerm: PropTypes.string.isRequired,
  setTrialLength: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  setRawPriceTotal: PropTypes.func.isRequired,
  setSubscriptionId: PropTypes.func.isRequired,
  initialPromoCode: PropTypes.string,
  setPromoCode: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array.isRequired,
  setProducts: PropTypes.func.isRequired,
  setCouponData: PropTypes.func.isRequired,
  isAmex: PropTypes.bool.isRequired,
  organizationPk: PropTypes.number,
};

export default PlanSelectionForm;
