import React, { useEffect, useState } from 'react';
import {
  createSubscriptionWithoutPaymentMethod,
  getPriceDetails,
  getTotalUnitAmountFromQuantity,
} from '../../utils';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function PlanSelectionForm({
  userIsExistingCustomer,
  setRegistrationStage,
  skipPaymentMethodRegistrationStage,
  paymentMethodRegistrationStage,
  setSelectedPrice,
  selectedPrice,
  setSelectedProductName,
  selectedProductName,
  setSelectedPriceTerm,
  selectedPriceTerm,
  setTrialLength,
  accountType,
  setRawPriceTotal,
  setSubscriptionId,
  initialPromoCode,
  setPromoCode,
  products,
  setProducts,
  setCouponData,
  isAmex,
  organizationPk,
}) {
  const [showAnnual, setShowAnnual] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedPriceUnitAmount, setSelectedPriceUnitAmount] = useState(null);

  useEffect(() => {
    // If a price is selected, check if it is a free tier
    if (selectedPrice) {
      getPriceDetails(selectedPrice).then((price) => {
        if (price.unit_amount == 0) {
          freeTierAction();
          return;
        }
      });
    }

    fetch(`/api/pricing-plans/?type=${accountType}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setProcessing(false);

        if (data.length == 1 && !selectedPrice) {
          setSelectedPrice(
            data[0][showAnnual ? 'yearly_price' : 'monthly_price']?.id
          );
          setSelectedPriceUnitAmount(
            data[0][showAnnual ? 'yearly_price' : 'monthly_price']?.unit_amount
          );
        }
      });
  }, []);

  /**
   * Moves to the next step in the registration process
   */
  function nextStep() {
    setProcessing(true);
    if (selectedPriceUnitAmount === 0) {
      freeTierAction();
    } else {
      setRegistrationStage(paymentMethodRegistrationStage);
    }
    setProcessing(true);
  }

  /**
   * Create a subscription without a payment method (for free tier plans)
   */
  function freeTierAction() {
    createSubscriptionWithoutPaymentMethod(selectedPrice, organizationPk).then(
      (r) => {
        if (!r.hasOwnProperty('error')) {
          setSubscriptionId(r.id);
          setRegistrationStage(skipPaymentMethodRegistrationStage);
        } else {
          setErrorMessage(r.error);
        }
      }
    );
  }

  function setSelection(product) {
    if (!selectedPrice) {
      window.analytics?.track('Plan Selected', {
        price_id: product[showAnnual ? 'yearly_price' : 'monthly_price']?.id,
        plan_name: product.name,
        plan_term: showAnnual ? 'Annual' : 'Monthly',
      });
    } else {
      window.analytics?.track('Plan Selection Changed', {
        new_price_id:
          product[showAnnual ? 'yearly_price' : 'monthly_price']?.id,
        new_plan_name: product.name,
        new_plan_term: showAnnual ? 'Annual' : 'Monthly',
        old_price_id: selectedPrice,
        old_plan_name: selectedProductName,
        old_plan_term: selectedPriceTerm,
      });
    }

    setSelectedPrice(
      product[showAnnual ? 'yearly_price' : 'monthly_price']?.id
    );
    setSelectedPriceUnitAmount(
      product[showAnnual ? 'yearly_price' : 'monthly_price']?.unit_amount
    );
    setSelectedProductName(product.name);
    setSelectedPriceTerm(showAnnual ? 'Annual' : 'Monthly');

    if (!initialPromoCode) {
      const promo = getActivePromoForProduct(product, showAnnual);
      if (promo) {
        if (promo.trial_days) {
          setTrialLength(promo.trial_days);
        }
        if (promo.promo_code) {
          setPromoCode(promo.promo_code);
        }
      } else {
        setTrialLength(0);
        setPromoCode('');
        setCouponData({});
      }
    }

    setRawPriceTotal(getTotalUnitAmountFromQuantity(product, null, showAnnual));
  }

  function getDisplayPrice(product, showAnnual) {
    if (showAnnual) {
      // remove decimal places if they are 0
      return product.yearly_price_usd_unit_amount % 1 === 0
        ? parseInt(product.yearly_price_usd_unit_amount)
        : product.yearly_price_usd_unit_amount;
    }
    return product.monthly_price_usd_unit_amount % 1 === 0
      ? parseInt(product.monthly_price_usd_unit_amount)
      : product.monthly_price_usd_unit_amount;
  }

  function getActivePromoForProduct(product, isAnnual) {
    const url_based_promos = product.promos.filter((promo) => {
      if (
        promo.url_path_requirement &&
        window.location.pathname.includes(promo.url_path_requirement) &&
        promo.subscription_term === (isAnnual ? 'annual' : 'monthly')
      ) {
        return promo;
      }
    });

    if (url_based_promos.length > 0) {
      return url_based_promos[0];
    }

    const default_promos = product.promos.filter((promo) => {
      if (
        promo.is_default &&
        promo.subscription_term === (isAnnual ? 'annual' : 'monthly')
      ) {
        return promo;
      }
    });

    if (default_promos.length > 0) {
      return default_promos[0];
    }

    return null;
  }

  function getPromoComponent(product, isAnnual) {
    if (initialPromoCode || userIsExistingCustomer) {
      return '';
    }

    const promo = getActivePromoForProduct(product, isAnnual);
    if (promo) {
      return (
        <div className={`promo-block ${promo.css_class}`}>
          <img src={promo.icon} className="promo-block__icon" />
          <div className="promo-block__text">{promo.text}</div>
          <div className={`promo-block__label ${promo.label_css_class}`}>
            {promo.label}
          </div>
        </div>
      );
    }
    return '';
  }

  return (
    <div>
      <div className="plan-selection-component">
        <div className="multi-step-flex-header">
          <h3 className="multi-step-flex-header__title">Select a Plan:</h3>
          <div className="multi-step-flex-header__term-switch">
            <div
              className={`multi-step-flex-header__term-name ${
                !showAnnual ? 'multi-step-flex-header__term-name--active' : ''
              }`}
            >
              Billed Monthly
            </div>
            <label className="toggle-switch">
              <input
                id="pricingToggleSwitch"
                className="toggle-switch__input"
                type="checkbox"
                value={showAnnual}
                onChange={(e) => {
                  setShowAnnual(!showAnnual);
                }}
              />
              <span className="toggle-switch__slider toggle-switch__slider--round toggle-switch__slider--stay-red"></span>
            </label>
            <div
              className={`multi-step-flex-header__term-name ${
                showAnnual ? 'multi-step-flex-header__term-name--active' : ''
              }`}
            >
              Billed Annually
              {products[0]?.discount_display_string && !isAmex && (
                <div
                  className={`multi-step-flex-header__term-promo ${
                    showAnnual
                      ? 'multi-step-flex-header__term-promo--active'
                      : ''
                  }`}
                >
                  ({products[0]?.discount_display_string})
                </div>
              )}
            </div>
          </div>
        </div>
        {processing ? (
          <>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={145} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={145} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={145} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={20} />
            </div>
            <div className="mulit-step-loader-container">
              <LoadingRectangle height={40} />
            </div>
          </>
        ) : (
          <>
            {errorMessage && <div>{errorMessage}</div>}
            <div className="plans-container">
              {products.map((product) => {
                return product[
                  showAnnual ? 'yearly_price' : 'monthly_price'
                ] ? (
                  <div
                    className={
                      selectedPrice ==
                      product[showAnnual ? 'yearly_price' : 'monthly_price']?.id
                        ? 'plan-item plan-item--selected'
                        : 'plan-item'
                    }
                    onClick={() => setSelection(product)}
                    key={product.id}
                    data-testid="planSelectionItem"
                  >
                    {getPromoComponent(product, showAnnual)}
                    <div className="plan-item__header">
                      <div className="plan-item__header__title">
                        {product.name}
                      </div>
                      <div className="plan-item__header__price">
                        ${getDisplayPrice(product, showAnnual)}/mo
                      </div>
                      {product.is_tiered && (
                        <div
                          className="plan-item__header__price-detail"
                          data-testid="perClientDesc"
                        >
                          per brand
                        </div>
                      )}
                    </div>
                    <div className="plan-item__content">
                      {product.description}
                    </div>
                  </div>
                ) : (
                  ''
                );
              })}
            </div>
            {products[0]?.disclaimer_display_string && (
              <p className="pricing-disclaimer">
                {products[0]?.disclaimer_display_string}
              </p>
            )}
            <p
              className="pricing-details"
              onClick={() =>
                window.open(
                  '/pricing',
                  'winname',
                  'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600'
                )
              }
            >
              ℹ Pricing Details
            </p>
            <button
              className="multi-step-button"
              onClick={nextStep}
              disabled={!selectedPrice}
              data-testid="continueButton"
            >
              {selectedPrice ? 'Continue' : 'Please select a plan'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default PlanSelectionForm;
