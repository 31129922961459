import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, InputAdornment, Grid } from '@mui/material';
import SocialSignInButton from './SocialSignInButton';
import useLocalStorage from '../../../../../../../config/static/js/utils/useLocalStorage';

const ShopifySignInForm = ({
  signInUrl = '',
  accountType = 'brand',
  csrfToken,
  guestCheckout,
  mediaProfilePk,
  isDisabled,
  onSubmit,
}) => {
  const [shopifyShopId, setShopifyShopId] = useState('');
  const [shopifySignInUrl, setShopifySignInUrl] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(isDisabled);
  const [myShopifyUrl] = useLocalStorage('myShopifyUrl', '');

  const getShopifyURL = () => {
    return `${shopifyShopId}.myshopify.com`;
  };

  useEffect(() => {
    // Update the button disabled state based on parent isDisabled and shopifyShopId validity
    setIsButtonDisabled(
      isDisabled || error || !shopifyShopId || shopifyShopId.length === 0
    );
    if (shopifyShopId) {
      setShopifySignInUrl(`${signInUrl}?shop=${getShopifyURL()}`);
    }
    // update Intercom to trigger tooltip display
    if (window.Intercom) window.Intercom('update');
  }, [isDisabled, error, shopifyShopId]);

  const validateShopUrl = (url) => {
    const regex = /^(?!-)[a-zA-Z0-9-]+(?<!-)$/;
    return regex.test(url);
  };

  const handleShopifyShopChange = (event) => {
    const newValue = event.target.value;
    const isValid = validateShopUrl(newValue);
    if (isValid) {
      setError(false);
      setHelperText('');
    } else {
      setError(true);
      setHelperText(
        'Please enter your valid Shopify store ID (only letters, numbers, and hyphens are allowed)'
      );
    }
    setShopifyShopId(newValue);
  };

  // if myShopifyUrl is set, parse url and set shopifyShopId to the value
  useEffect(() => {
    if (myShopifyUrl) {
      let urlString = myShopifyUrl;
      if (!/^https?:\/\//i.test(urlString)) {
        urlString = 'https://' + urlString;
      }
      const url = new URL(urlString);
      const shopifyShopId = url.hostname.split('.')[0];
      setShopifyShopId(shopifyShopId);
    }
  }, [myShopifyUrl]);

  return (
    <Box my={2}>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item sm={10}>
          <TextField
            variant="outlined"
            value={shopifyShopId}
            onChange={handleShopifyShopChange}
            margin="none"
            placeholder="[shopify-shop-id]"
            error={error}
            helperText={helperText}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">.myshopify.com</InputAdornment>
              ),
              inputProps: {
                'data-intercom-target': 'shopify-shop-id-tooltip',
              },
            }}
          />
        </Grid>
        <Grid item sm={2}>
          {onSubmit ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit(getShopifyURL())}
              disabled={isButtonDisabled}
            >
              Continue with Shopify
            </Button>
          ) : (
            <SocialSignInButton
              signInUrl={shopifySignInUrl}
              accountType={accountType}
              csrfToken={csrfToken}
              guestCheckout={guestCheckout}
              mediaProfilePk={mediaProfilePk}
              imgSrc={`${staticUrl}/images/svg/logos_shopify-icon.svg`}
              imgAlt="shopify-logo"
              isDisabled={isButtonDisabled}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShopifySignInForm;
